import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import "tachyons";
import {
  FaPinterestP,
  FaFacebookF,
  FaTwitter,
} from 'react-icons/fa';
import {FiMail} from 'react-icons/fi';
// import { OutboundLink } from "gatsby-plugin-google-analytics";
import { OutboundLink } from "gatsby-plugin-google-gtag"

export default (props) => {
  let { title,category, author, date, timeToRead, desc, img, location } = props;
  location = encodeURIComponent(location.pathname);
  desc = encodeURIComponent(desc);
  img = encodeURIComponent(img);
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }  
      `}
      render={data => {
        const base = encodeURIComponent(data.site.siteMetadata.siteUrl);
        return (
          <div className="bg-lightest-blue near-black ph2 pv5 flex flex-column justify-center items-center">
            <Link to={`/${category}`} className="sans-serif ttu mid-gray tracked f6 no-underline underline-hover">{category}</Link>
            <h1 className="dark-gray display fw4 f1-l f2 tc">{title}</h1>
            <span className="sans-serif tracked ttu f6 mb2">by {author}</span>
            <span className="sans-serif tracked ttu f6">{date}</span>
            <span className="sans-serif tracked ttu f6">{timeToRead} mins read</span>
            <div className="pv4 ph3 ph5-ns tc">
              <OutboundLink
                href={`https://pinterest.com/pin/create/button/?url=${base + location}&description=${desc}&media=${base + img}`}
                className="link dim gray dib h1 w2 br-100 mr3"><FaPinterestP /></OutboundLink>
              <OutboundLink
                href={`https://twitter.com/share?ref_src==${base + location}`}
                className="link dim gray dib h1 w2 br-100 mr3"><FaTwitter /></OutboundLink>
              <OutboundLink
                href={`https://www.facebook.com/sharer/sharer.php?u=${base + location}`}
                className="link dim gray dib h1 w2 br-100 mr3"><FaFacebookF /></OutboundLink>
              <OutboundLink
                href={`mailto:?&body=${base + location}`}
                className="link dim gray dib h1 w2 br-100 mr3"><FiMail /></OutboundLink>
            </div>
          </div>
        )
      }} />)
}
