import React from 'react';
import Layout from '../common/layouts';
import Hero from './components/hero';
import Body from './components/body';
import Seo from './seo';
import MetaSeo from '../common/seo';
import { graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react'
import * as shortcodes from '@blocks/kit'


export default ({ location, data }) => {
  const {
    category,
    date,
    dateOriginal,
    author,
    title,
    slug,
    metaDescription,
    keywords,
    featured,
  } = data.post.frontmatter;

  const content = data.post.body;
  const toc = data.post.tableOfContents;
  const ttr = data.post.timeToRead;
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title },
  }
  return (
    <Layout>
      <Seo
        slug={slug}
        title={title}
        date={dateOriginal}
        description={metaDescription}
        author={author}
        image={featured.childImageSharp.original.src} />
      <MetaSeo
        title={title}
        description={metaDescription}
        keywords={keywords}
        image={featured.childImageSharp.resize} />
      <Hero author={author}
        date={date}
        category={category}
        title={title}
        timeToRead={ttr}
        img={featured.childImageSharp.original.src}
        desc={metaDescription}
        location={location} />
      <MDXProvider components={shortcodes}>
        <Body
          content={content}
          description={metaDescription}
          image={featured.childImageSharp.original.src}
          location={location}
          headings={toc}
          disqusConfig={disqusConfig}
        />
      </MDXProvider>
    </Layout>
  )
}


export const query = graphql`query ($slug: String!) {
  post: mdx(frontmatter: {slug: {eq: $slug}}) {
    body
    tableOfContents
    timeToRead
    frontmatter {
      date(formatString: "MMM Do, YYYY")
      dateOriginal: date
      category
      author
      title
      metaDescription
      keywords
      slug
      featured {
        childImageSharp {
          original {
            src
          }
          gatsbyImageData(layout: FULL_WIDTH)
          resize(width: 1080) {
            src
            height
            width
          }
        }
      }
    }
  }
  date: mdx(frontmatter: {slug: {eq: $slug}}) {
    frontmatter {
      date
    }
  }
}
`
