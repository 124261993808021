import React from 'react';
import EmailForm from './emailForm';
import { StaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import 'tachyons';


export default props => (
  <div className="flex flex-column justify-end items-center pa2" style={{gridArea: "suggested"}}>
    <span className="sans-serif tracked ttu tc db pv3">CONTINUE READING</span>
    <StaticQuery
      query={graphql`{
  allMdx(
    limit: 2
    sort: {order: DESC, fields: frontmatter___date}
    filter: {frontmatter: {type: {eq: "post"}}}
  ) {
    edges {
      node {
        frontmatter {
          title
          slug
          metaDescription
          featured {
            childImageSharp {
              gatsbyImageData(width: 720, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`}
      render={data => data.allMdx.edges.map(({ node }) => (
        <div key={node.frontmatter.slug} className="w-100 mw6 tc mb4">
          <Link to={node.frontmatter.slug}>
            <GatsbyImage
              image={node.frontmatter.featured.childImageSharp.gatsbyImageData}
              className="h5"
              alt={node.frontmatter.metaDescription} />
          </Link>
          <Link
            className="f4 serif tc dib pv2 ph3 display dark-gray no-underline no-underline underline-hover grow"
            to={node.frontmatter.slug}>
            {node.frontmatter.title}
          </Link>
        </div>
      ))} />
    {/* <EmailForm /> */}
  </div>
)
